import React, { FC } from 'react'
import styled from 'styled-components'
import { colours } from '../../consts/styles'

type TextAlign = 'left' | 'center'

const Wrapper: any = styled.div`
  display: ${(props: any) => props.desktopOnly ? 'none' : 'block'};
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
  text-align: ${(props: any) => props.textAlign};

  img {
    display: block;
    width: 100%;
  }

  &:after {
    content: '';
    display: ${(props: any) => props.overlay ? 'block' : 'none'};
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    top: 20px;
    left: 20px;
    background: ${colours.secondary};
    opacity: .2;
  }

  @media (min-width: 1080px) {
    display: block;
    padding: 20px;
  }
`

const ContentBox: FC<{
  desktopOnly?: boolean,
  header?: string,
  id?: string,
  overlay?: boolean,
  textAlign?: TextAlign
}> = ({
  children,
  desktopOnly,
  header,
  id,
  overlay,
  textAlign = 'left'
}) => (
  <Wrapper desktopOnly={desktopOnly} id={id} overlay={overlay} textAlign={textAlign}>
    {header && <h2>{header}</h2>}
    {children}
  </Wrapper>
)

const ContentInner: FC = styled.div`
  display: block;

  > div {
    width: 100%;
  }

  @media (min-width: 1080px) {
    display: flex;
  }
`

const ContentWrapper: FC = styled.div`
  position: relative;
  width: 1000px;
  max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  z-index: 10;
  border-top: 1px solid ${colours.primary};
  border-radius: 0 0 7px 7px;

  > div,
  > p,
  > h2 {
    position: relative;
    z-index: 1;
  }

  img {
    max-width: 440px;
  }

  &:after {
    background: ${colours.background};
    border-radius: 0 0 7px 7px;
    content: '';
    opacity: .5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  @media (min-width: 1080px) {
    margin: 40px auto;
    padding: 40px;
  }
`

export { ContentBox, ContentInner, ContentWrapper }
