import { keyframes } from 'styled-components'

const colours = {
  background: '#111111',
  grey: '#C5C6C7',
  primary: '#95E6C8',
  secondary: '#6AA690',
  white: '#FFFFFF',
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    background-color: ${colours.background};
    opacity: 1;
  }
  to {
    background-color: ${colours.background};
    opacity: 0.85;
  }
`

const float = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(calc(-3150px + 100vh));
  }
`

const heartbeat = keyframes`
  0% {
    transform: scale(.85);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(.85);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(.85);
  }
  100% {
    transform: scale(.85);
  }
`

const wobble = keyframes`
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(-10px);
  }
  66% {
    transform: translateX(10px);
  }
  100%{
    transform: translateX(0px);
  }
`

export { colours, fadeIn, fadeOut, float, heartbeat, wobble }
