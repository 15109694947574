import React, { FC } from 'react'
import styled from 'styled-components'
import { colours } from '../../consts/styles'
import { Overlay } from '../'

const Container = styled.div`
  text-align: center;
  color: ${colours.white};
  padding-bottom: 40px;
  position: relative;
  overflow-x: hidden;

  h1, h2, h3 {
    font-weight: 300;
    text-transform: uppercase;
  }

  h1 {
    font-weight: 200;
  }

  h2 {
    color: ${colours.primary};
  }

  a {
    color: ${colours.primary};
    transition: all 0.3s ease;
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${colours.secondary};
    }
  }

  .images {
    > img {
      display: none;
      float: left;
      margin: 20px 0 0;

      &:nth-child(1) {
        display: block;
      }

      &:nth-child(2) {
        margin: 20px 3.5% 0 3.5%;
      }
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    @media (min-width: 1080px) {
      > img {
        max-width: 31%;

        &:nth-child(2),
        &:nth-child(3) {
          display: block;
        }
      }
    }
  }
`

const Background = styled.div`
  background-image: url('/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 100vw;
`

const Wrapper: FC = ({ children }): JSX.Element => (
  <Container>
    <Background />
    <Overlay />
    {children}
  </Container>
)

export { Wrapper }
