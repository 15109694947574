import React from 'react'
import {
  Banner,
  ContentBox,
  ContentInner,
  ContentWrapper,
  StyledList,
  Wrapper
} from './components'

const App = () => {
  return (
    <div>
      <Wrapper>
        <Banner>
          <h1>Baxter Body Science</h1>
        </Banner>
        <ContentWrapper>
          <ContentInner>
            <ContentBox header="Who we are">
              <p>Baxter Body Science was opened by Amelia in order to provide services of high quality and a welcoming space for every individual wanting to achieve their fitness and wellbeing goals.</p>
              <p>Having worked in a commercial gym, she realised that she wanted to create her own private space that was friendly, clean and approachable in order to provide evidence based practice to training programs.</p>
              <p>In order for you to reach your goals Amelia’s aim is to make exercise enjoyable, educational and individualised for clients, while providing an evidence based approach to training.</p>
            </ContentBox>
            <ContentBox header="Services">
              <p>We provide competitive prices for the following services.</p>
              <StyledList items={['Personal training', 'Couples training', 'Online small group training', 'Outdoor sessions', 'Online coaching']} />
              <p>If you like a quote on any of our services please <a href="#contact-us">get in contact</a>.</p>
            </ContentBox>
          </ContentInner>
        </ContentWrapper>
        <ContentWrapper>
          <ContentInner>
            <ContentBox header="The gym" textAlign="center">
              <p>At Baxter Body Science you'll be training in our own private gym.</p>
              <div className="images">
                <img alt="Gym shot 1" src="/gym-1.jpeg" />
                <img alt="Gym shot 2" src="/gym-2.jpeg" />
                <img alt="Gym shot 3" src="/gym-3.jpeg" />
              </div>
            </ContentBox>
          </ContentInner>
        </ContentWrapper>
        <ContentWrapper>
          <ContentInner>
            <ContentBox desktopOnly overlay>
              <img alt="Artarmon, NSW" src="/map.gif" />
            </ContentBox>
            <ContentBox header="Get in touch" id="contact-us">
              <h3>Contact details</h3>
              <p><strong>Phone</strong>: <a href="tel:+61407783891">0407 783 891</a></p>
              <p><strong>Email</strong>: <a href="mailto:amelia@baxterbodyscience.com.au">amelia@baxterbodyscience.com.au</a></p>
              <p><strong>Location</strong>: Palmer St, Artarmon</p>
            </ContentBox>
          </ContentInner>
        </ContentWrapper>
        <ContentWrapper>
          <ContentInner>
            <ContentBox header="About Amelia" textAlign="center">
              <p>Amelia is a Sport and Exercise Scientist working as a personal trainer since 2017. She has 16 years experience as a professional ballet dancer. During this time has worked in international ballet companies, represented Australia in competitive ballet competitions, and received a scholarship to the Australian Ballet School.</p>
              <p>She has gained qualifications including a Bachelor of Sport and Exercise Science with Distinction at the University of Technology Sydney, the Advanced Diploma of Dance at the Australian Ballet school  and is  Studio Pilates certified.</p>
              <p>Amelia’s own preference of exercise includes running, resistance training and Pilates.</p>
            </ContentBox>
            <ContentBox>
              <img alt="Amelia Baxter, founder Baxter Body Science" src="/amelia.jpg" />
            </ContentBox>
          </ContentInner>
        </ContentWrapper>
        <ContentWrapper>
          <p>{`\u00A9 ${new Date().getFullYear()} Baxter Body Science. All rights reserved.`}</p>
        </ContentWrapper>
      </Wrapper>
    </div>
  )
}

export default App
