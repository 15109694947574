import React, { FC } from 'react'
import styled from 'styled-components'
import { WeightHanging as Icon } from '@styled-icons/fa-solid/WeightHanging'
import { colours } from '../../consts/styles'

const List: any = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem: FC = styled.li`
  display: flex;
  margin: 16px 0;
  vertical-align: center;

  svg {
    path {
      fill: ${colours.primary};
    }
  }

  span {
    font-weight: 800;
    margin-left: 10px;
  }
`

const StyledList: FC<{ items: string[]}> = ({ items }) => (
  <List>
    {items.map((item, i) => <ListItem key={`item${i}`}><Icon size="20" /> <span>{item}</span></ListItem>)}
  </List>
)

export { StyledList }
