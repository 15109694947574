import React, { FC } from 'react'
import { colours } from '../../consts/styles'

const Logo: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4427 2975">
    <path
      d="M1367.277 545.82c186.072-9.212 283.76-110.535 316.322-184.225
    23.26-50.662 9.304-73.69-46.518-78.295C1311.456 255.666 892.794 29.99
    581.124 11.568 343.881-2.249 115.943 71.441 32.211 384.623c-83.733 308.576
    4.651 566.491 162.812 769.138 134.903 170.408 381.448 400.689 725.68
    548.068-88.383 41.451-162.812 105.93-223.286 193.436-97.687 142.774-93.036
    414.506 106.992 630.97 204.679 221.07 553.563 382.266 814.064 428.322
    176.768 32.24 288.411 27.634 390.751-23.028 93.036-46.056 176.768-124.352
    237.242-211.858 69.777-101.324 116.294-175.014
    9.303-469.773-186.072-515.83-539.608-1188.25-888.493-1704.078zM1583
    1574C661.2 1362.42 88.568 741.48 204.957 428.71c93.11-239.176 554.01-27.596
    875.243 73.594-9.31 101.19 23.278 239.177 139.667 450.756 93.111 170.184
    228.122 386.363 363.133 620.94zm-361.514 232c65.189 13.797 130.378 22.995
    195.566 32.193 130.378 13.797 223.504 0 288.693-32.193 125.72 239.15 242.13
    482.9 311.974 712.852 51.22 160.967 0 216.155-149.002
    197.76-298.006-36.793-698.45-225.354-898.673-469.104C797.76 2035.952 797.76
    1828.995 1221.486 1806z"
      fill={colours.primary}
    />
    <path
      d="M3059.723 535.82c-186.072-9.212-283.76-110.535-316.322-184.225-23.26-50.662-9.304-73.69 46.518-78.295 325.625-27.634 744.287-253.31 1055.957-271.732 237.242-13.817 465.18 59.873 548.912 373.055 83.733 308.576-4.651 566.491-162.812 769.138-134.902 170.408-381.448 400.689-725.68 548.068 88.384 41.451 162.812 105.93 223.286 193.436 97.687 142.774 93.036 414.506-106.992 630.97-204.679 221.07-553.563 382.266-814.064 428.322-176.768 32.24-288.411 27.634-390.751-23.028-93.036-46.056-176.768-124.352-237.242-211.858-69.777-101.324-116.294-175.014-9.303-469.773 186.072-515.83 539.608-1188.25 888.493-1704.078zM2844 1564c921.8-211.58 1494.432-832.52 1378.043-1145.29-93.11-239.176-554.01-27.596-875.243 73.594 9.31 101.19-23.278 239.177-139.667 450.756-93.111 170.184-228.122 386.363-363.133 620.94zm361.514 232c-65.189 13.797-130.378 22.995-195.566 32.193-130.378 13.797-223.504 0-288.693-32.193-125.72 239.15-242.13 482.9-311.974 712.852-51.22 160.967 0 216.155 149.002 197.76 298.006-36.793 698.45-225.354 898.673-469.104 172.284-211.556 172.284-418.513-251.442-441.508z"
      fill={colours.primary}
    />
  </svg>
)

export { Logo }
