import { FC } from 'react'
import styled from 'styled-components'
import { colours, fadeOut } from '../../consts/styles'

const Overlay: FC = styled.div`
  animation: ${fadeOut} 2s ease 1s 1 normal forwards;
  background-color: ${colours.background};
  height: 100%;
  opacity: 1;
  position: fixed;
  width: 100vw;
`

export { Overlay }
