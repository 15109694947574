import React, { FC, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ArrowIosDownwardOutline as Down } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline'
import { colours, fadeIn, heartbeat } from '../../consts/styles'
import { Logo } from '../'

const Wrapper: FC = styled.div`
  height: 100vh;
  position: relative;
`
const Inner: FC = styled.div`
  max-width: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    animation: ${heartbeat} 3s infinite;
    max-width: 300px;
    width: 80%;
  }
`

const ScrollBtn: any = styled.button`
  animation: ${fadeIn} 3s ease 1s 1 normal forwards;
  background: none;
  border: 0 none;
  outline: 0 none;
  cursor: pointer;
  bottom: 20px;
  font-size: 1.1rem;
  font-weight: 300;
  left: 50%;
  opacity: ${(props: any) => props.show ? 0 : '0 !important'};
  position: absolute;
  pointer-events: ${(props: any) => props.show ? 'auto' : 'none'};
  text-transform: uppercase;
  transform: translateX(-50%);
  transition: all 0.5s ease;

  &:hover {
    path {
      fill: ${colours.secondary};
    }
  }

  path {
    fill: ${colours.primary};
    transition: all 0.5s ease;
  }
`

const scrollToContent = (): void => {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  )

  return window.scrollTo({
    top: vh,
    left: 0,
    behavior: 'smooth',
  })
}

const Banner: FC = ({ children }) => {
  const [showScroll, setShowScroll] = useState(true)
  const handleScroll = useCallback(() => {
    const vh = () =>
      Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )

    const scrollPos = () =>
      window.scrollY ||
      // window.scrollTop ||
      document.getElementsByTagName('html')[0].scrollTop

    setShowScroll(!(scrollPos() > vh() / 3))
  }, [setShowScroll])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Wrapper>
      <Inner>
        <Logo />
        {children}
      </Inner>
      <ScrollBtn onClick={scrollToContent} show={showScroll}>
        <Down size={80} />
      </ScrollBtn>
    </Wrapper>
  )
}

export { Banner }
